// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
// RxJS
import { Observable} from 'rxjs';
// Models
import { CargoModel, MarcaModel, SimpleResponse, MarcaListaResponse} from "../models";

// Environment
import { environment } from 'src/environments/environment';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class MarcaService {
    static periodoPath = environment.APIEndpoint + 'api-maestras/marca';

    constructor(private http: HttpClient) {
    }

    getPage( pageSize:number = 5, currentPage:number=1, search:string='', orderBy:string='1' ): Observable<SimpleResponse>{
        return this.http.get<SimpleResponse>(MarcaService.periodoPath+`?pageSize=${pageSize}&currentPage=${currentPage}&search=${search.toUpperCase()}` );
        //+ `?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`
    }


    // READ PAQUETE BY ID
    get( id:number ): Observable<SimpleResponse>{
         return this.http.get<SimpleResponse>(MarcaService.periodoPath + `/${id}`);
     }
    
     getMarcaAll( ): Observable<MarcaListaResponse>{
        return this.http.get<MarcaListaResponse>(MarcaService.periodoPath  );
    }

     // CREATE PAQUETE
     post( body:MarcaModel): Observable<SimpleResponse>{
        return this.http.post<SimpleResponse>(MarcaService.periodoPath, body );
      
     }
 
     // UPDATE PAQUETE BY ID
     put( body:MarcaModel ): Observable<SimpleResponse>{
         return this.http.put<SimpleResponse>(MarcaService.periodoPath + `/${body.mrcId}`, body);
        
     }
 
     // DELETE PAQUETE BY ID
     delete( id:number ): Observable<SimpleResponse>{
         return this.http.delete<SimpleResponse>(MarcaService.periodoPath + `/${id}`);

       
     }
 
 
}
