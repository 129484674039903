import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

export interface Permiso {
    id     : number;
    name   : string;
    modulo : string;
}

export interface Rol {
    id       : number;
    name     : string;
    permisos : number[];
}
  

@Injectable({ providedIn: 'root' })
export class PermisoService {

    private _permisos:Permiso[] = [
        //Ventas
        //  PADRE
        {
            id 	    : 100,
            name    : 'accessSalesModule',
            modulo  : 'moduleAPPSales'
        },
        //  HIJO
        {
            id 	    : 101,
            name    : 'accessSalesApppedidoComponent',
            modulo  : 'moduleAPPSales'
        },
        //  HIJO
        {
            id 	    : 102,
            name    : 'accessSalesApppedidodetalleComponent',
            modulo  : 'moduleAPPSales'
        },
        //Compras
        //  PADRE
        {
            id 	    : 200,
            name    : 'accessPurchasesModule',
            modulo  : 'moduleAPPPurchases'
        },
        //  HIJO
        {
            id 	    : 201,
            name    : 'accessPurchasesAppcompraComponent',
            modulo  : 'moduleAPPPurchases'
        },
        //  HIJO
        {
            id 	    : 202,
            name    : 'accessPurchasesAppcompradetalleComponent',
            modulo  : 'moduleAPPPurchases'
        },

        //Caja
        //  PADRE
        {
            id 	    : 300,
            name    : 'accessCashregisterModule',
            modulo  : 'moduleAPPCashregister'
        },
        //  HIJO
        {
            id 	    : 301,
            name    : 'accessCashregisterAppcobroComponent',
            modulo  : 'moduleAPPCashregister'
        },
        //  HIJO
        {
            id 	    : 302,
            name    : 'accessCashregisterAppefectivoComponent',
            modulo  : 'moduleAPPCashregister'
        },
        //  HIJO
        {
            id 	    : 303,
            name    : 'accessCashregisterAppnotacreditoComponent',
            modulo  : 'moduleAPPCashregister'
        },
        //  HIJO
        {
            id 	    : 304,
            name    : 'accessCashregisterAppnotadebitoComponent',
            modulo  : 'moduleAPPCashregister'
        },
        //  HIJO
        {
            id 	    : 305,
            name    : 'accessCashregisterAppcierrecajaComponent',
            modulo  : 'moduleAPPCashregister'
        },
        //Almacen
        //  PADRE
        {
            id 	    : 400,
            name    : 'accessStoreModule',
            modulo  : 'moduleAPPStore'
        },
        //  HIJO
        {
            id 	    : 401,
            name    : 'accessStoreAppinventarioComponent',
            modulo  : 'moduleAPPStore'
        },
        //  HIJO
        {
            id 	    : 402,
            name    : 'accessStoreAppinventarioinicialComponent',
            modulo  : 'moduleAPPStore'
        },
        //  HIJO
        {
            id 	    : 403,
            name    : 'accessStoreAppInventarioAppkardexComponent',
            modulo  : 'moduleAPPStore'
        },
        //  HIJO
        {
            id 	    : 404,
            name    : 'accessStoreApptransferdetalleComponent',
            modulo  : 'moduleAPPStore'
        },
        //  HIJO
        {
            id 	    : 405,
            name    : 'accessStoreApptransferenciaComponent',
            modulo  : 'moduleAPPStore'
        },
        //Reportes
        //  PADRE
        {
            id 	    : 500,
            name    : 'accessAdminAdComponent',
            modulo  : 'moduleAPPApplications'
        },

        //Gestión
        //  PADRE
        {
            id 	    : 600,
            name    : 'accessManagementModule',
            modulo  : 'moduleAPPManagement'
        },
        //  HIJO - Personas
        {
            id 	    : 610,
            name    : 'accessManagementPeopleModule',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 611,
            name    : 'accessManagementAppclienteComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 612,
            name    : 'accessManagementAppproveedorComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 613,
            name    : 'accessManagementApppersonalComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 614,
            name    : 'accessManagementAppcargoComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  HIJO - Catálogo
        {
            id 	    : 620,
            name    : 'accessManagementCatalogueModule',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 621,
            name    : 'accessManagementAppcategoriaComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 622,
            name    : 'accessManagementAppmarcaComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 623,
            name    : 'accessManagementApppresentacionproductoComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 624,
            name    : 'accessManagementAppunidadmedidaComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 625,
            name    : 'accessManagementAppproductoComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 626,
            name    : 'accessManagementAppsucursalproductoComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  HIJO - General
        {
            id 	    : 630,
            name    : 'accessManagementGeneralModule',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 631,
            name    : 'accessManagementAppsucursalComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 632,
            name    : 'accessManagementAppcajaComponent',
            modulo  : 'moduleAPPManagement'
        },
        //  NIETO
        {
            id 	    : 633,
            name    : 'accessManagementApptalonarioComponent',
            modulo  : 'moduleAPPManagement'
        },
        // Configuración
        //  PADRE
        {
            id 	    : 700,
            name    : 'accessSettingModule',
            modulo  : 'moduleAPPSetting'
        },
        //  HIJO
        {
            id 	    : 701,
            name    : 'accessSettingApptipocomprobanteComponent',
            modulo  : 'moduleAPPSetting'
        },
        //  HIJO
        {
            id 	    : 702,
            name    : 'accessSettingApptipodocumentoComponent',
            modulo  : 'moduleAPPSetting'
        },
        //  HIJO
        {
            id 	    : 703,
            name    : 'accessSettingAppmediodepagoComponent',
            modulo  : 'moduleAPPSetting'
        },
        //  HIJO
        {
            id 	    : 704,
            name    : 'accessSettingAppbancoComponent',
            modulo  : 'moduleAPPSetting'
        },
        //  HIJO
        {
            id 	    : 705,
            name    : 'accessSettingAppgruporolComponent',
            modulo  : 'moduleAPPSetting'
        },
    ];

    private _roles:Rol[] = [
        {
            id 	     : 1,
            name     : 'ADMIN',
            permisos : [100,101,102,200,201,202,300,301,302,303,304,304,305,400,401,402,403,404,405,500,600,610,611,612,613,614,620,621,622,623,624,625,626,630,631,632,633,700,701,702,703,704,705]
        },
        {
            id 	     : 2,
            name     : 'USUARIO',
            permisos : [100,101,200,201,202,300,320,326,327,328,340,343,400,401]
        },
        {
            id 	     : 3,
            name     : 'PARTNER',
            permisos : [100,102,200,201,202,300,320,325,340,344]

        }
    ];

    constructor() { }

    getPermisos(term: string = null): Observable<Permiso[]> {
        let items = this.permisos;
        if (term) {
            items = items.filter(x => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        }
        return of(items).pipe(delay(500));
    }

    getRoles(term: string = null): Observable<Rol[]> {
        let items = this.roles;
        if (term) {
            items = items.filter(x => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        }
        return of(items).pipe(delay(500));
    }

    public get permisos() {
        return this._permisos;
    }

    public get roles() {
        return this._roles;
    }

    getPermisosByRol( miRol:string = null){
        // var rol:Rol;
        // this.roles.forEach(item => {
        //     if(item.name === miRol) { rol = item }
        // });

        var rol = this.roles.find(
            x => x.name === miRol
          );

        var permisos: Permiso[] = [];
        
        rol.permisos.forEach((perm:number) => {
            this.permisos.forEach((item:Permiso) => {
                if(item.id === perm) { permisos.push(item)}
            });
        });
        // console.log(permisos);
        return permisos;
    }
}