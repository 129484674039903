
export { BancoService } from './banco.service';
export { SucursalService } from './sucursal.service';
export { CargoService } from './cargo2.service';
export { MarcaService } from './marca.service';
export { MediodepagoService } from './mediodepago.service';
export { GrupoRolService } from './gruporol.service';
export { CategoriaService } from './categoria.service';
export { TipoComprobanteService} from './tipo-comprobante.service'; 
export { TipoDocumentoService} from './tipo-documento.service';
export { PersonaService } from './persona.service';
export { UnidadmedidaService } from './unidadmedida.service';
export { ProductoService } from './producto.service';
export { PresentacionproductoService } from './presentacionproducto.service';
export { PedidoService } from './pedido.service';
export { TransferenciaService } from "./transferencia.service";
export { CajaService } from './caja.service';
export { TalonarioService } from './talonario.service';
export { CompraService } from './compra.service';
export { SucursalProductoService } from './sucursal-producto.service';
export { RolService } from './rol.service';
export { OperacionesCajaService } from './operacionescaja.service';

export { PermisoService, Permiso } from './permiso.service';