// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { CajaModel, CobrarPedidoModel, SimpleResponse} from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class OperacionesCajaService {
    static cajaPath = environment.APIEndpoint + 'api-caja/caja';
    
    constructor(private http: HttpClient) { }

    // CREATE PUBLICIDAD
    cobrarPedido( bodyCobrarPedido:CobrarPedidoModel ): Observable<SimpleResponse>{
        return this.http.post<SimpleResponse>(OperacionesCajaService.cajaPath+`/cobrar-pedido`, bodyCobrarPedido );
    }

    

}
