// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { BancoModel, BancoListaResponse, BancoModelResponse, BancosModelResponse, BancoUrlResponse } from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class BancoService {
    static bancoPath = environment.APIEndpoint + 'api-maestras/banco';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getBancoEnabled( ): Observable<BancoModel[]>{
        return this.http.get<BancoModel[]>(BancoService.bancoPath);
    }

    // READ ALL PUBLICIDADES
    getBancoAll( ): Observable<BancoListaResponse>{
        return this.http.get<BancoListaResponse>(BancoService.bancoPath  );
    }

    getAll( ): Observable<BancosModelResponse>{
        return this.http.get<BancosModelResponse>(BancoService.bancoPath+'/all'  );
    }

    // READ PAQUETE BY ID
    getBanco( idBanco:number ): Observable<BancoModelResponse>{
        return this.http.get<BancoModelResponse>(BancoService.bancoPath + `/${idBanco}`);
    }

    // CREATE PUBLICIDAD
    postBanco( bodyBanco:BancoModel ): Observable<BancoModelResponse>{
        return this.http.post<BancoModelResponse>(BancoService.bancoPath, bodyBanco );
    }

    // UPDATE PUBLICIDAD BY ID
    putBanco( bodyBanco:BancoModel ): Observable<BancoModelResponse>{
        return this.http.put<BancoModelResponse>(BancoService.bancoPath + `/${bodyBanco.bncId}`, bodyBanco);
    }

    // DELETE BY ID
    deleteBanco( idBanco:number ): Observable<BancoModelResponse>{
        return this.http.delete<BancoModelResponse>(BancoService.bancoPath + `/${idBanco}`);
    }

}
