// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreState } from './store.reducers';

// export const selectStoreState = state => state.store;
export const selectStoreState = createFeatureSelector<StoreState>('storereducer');

// TipoComprobante
export const isTipocomprobanteLoaded = createSelector(
    selectStoreState,
    store => store.isTipocomprobanteLoaded
);

export const currentTipocomprobante = createSelector(
    selectStoreState,
    storep => storep.tipocomprobante
);
// ..

// TipoDocumento
export const isTipodocumentoLoaded = createSelector(
    selectStoreState,
    store => store.isTipodocumentoLoaded
);

export const currentTipodocumento = createSelector(
    selectStoreState,
    storep => storep.tipodocumento
);

export const currentTipodocumentoHuman = createSelector(
    selectStoreState,
    storep => storep.tipodocumento.filter((td:any) => td.tdcEshumano)
);

export const currentTipodocumentoNoHuman = createSelector(
    selectStoreState,
    storep => {
        if(storep.tipodocumento) {
            return storep.tipodocumento.filter((td:any) => !td.tdcEshumano)
        } else {
            return storep.tipodocumento
        }
    } 
);

export const currentTipodocumentoPedido = createSelector(
    selectStoreState,
    storep => {
        if(storep.tipodocumento) {
            return storep.tipodocumento.filter((td:any) => (td.tdcCodigo != '11' && td.tdcCodigo != '7'))
        } else {
            return storep.tipodocumento
        }
    } 
);

// ..

// Banco
export const isBancoLoaded = createSelector(
    selectStoreState,
    store => store.isBancoLoaded
);

export const currentBanco = createSelector(
    selectStoreState,
    storep => storep.banco
);
// ..

// Rol
export const isRolLoaded = createSelector(
    selectStoreState,
    store => store.isRolLoaded
);

export const currentRol = createSelector(
    selectStoreState,
    storep => storep.rol
);
// ..

// Sucursal
export const isSucursalLoaded = createSelector(
    selectStoreState,
    store => store.isSucursalLoaded
);

export const currentSucursal = createSelector(
    selectStoreState,
    storep => storep.sucursal
);
// ..

