// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { ProductoModel, ProductoListaResponse, ProductoModelResponse, PrecioventaModelResponse, PrecioventaListaResponse, PrecioventaModel, UnidadesmedidaModel, } from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class ProductoService {
    static productoPath = environment.APIEndpoint + 'api-producto/producto';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getProductoEnabled( ): Observable<ProductoModel[]>{
        return this.http.get<ProductoModel[]>(ProductoService.productoPath);
    }

    // READ ALL PUBLICIDADES
    getProductoAll( ): Observable<ProductoListaResponse>{
        return this.http.get<ProductoListaResponse>(ProductoService.productoPath  );
    }
    
    //GET PAGE
    getProductosCombo(pageSize: number = 5, currentPage: number = 1, search: string = '', orderBy: string = '1'): Observable<ProductoListaResponse> {
        return this.http.get<ProductoListaResponse>(ProductoService.productoPath + `/list?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`);
        //+ `?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`
    }
    

    // READ PAQUETE BY ID
    getProducto( idProducto:number ): Observable<ProductoModelResponse>{
        return this.http.get<ProductoModelResponse>(ProductoService.productoPath + `/${idProducto}`);
    }

    // CREATE PUBLICIDAD
    postProducto( bodyProducto:ProductoModel ): Observable<ProductoModelResponse>{
        return this.http.post<ProductoModelResponse>(ProductoService.productoPath, bodyProducto );
    }

    // UPDATE PUBLICIDAD BY ID
    putProducto( bodyProducto:ProductoModel ): Observable<ProductoModelResponse>{
        return this.http.put<ProductoModelResponse>(ProductoService.productoPath + `/${bodyProducto.prdId}`, bodyProducto);
    }

    // DELETE BY ID
    deleteProducto( idProducto:number ): Observable<ProductoModelResponse>{
        return this.http.delete<ProductoModelResponse>(ProductoService.productoPath + `/${idProducto}`);
    }


    //Producto anuncio:

    // READ PRECIOS DE VENTA POR PRODUCTO
    getProductoPrecioventas( idProducto:number ): Observable<ProductoModelResponse>{
        return this.http.get<ProductoModelResponse>(ProductoService.productoPath + `/${idProducto}/precio-venta`);
    }

    putProductoPrecioventa( bodyProductoPrecio: PrecioventaModel, idProducto:number): Observable<PrecioventaModelResponse>{
        return this.http.put<PrecioventaModelResponse>(ProductoService.productoPath + `/${idProducto}/precio-venta/${bodyProductoPrecio.pvtId}`, bodyProductoPrecio);
    }
    
    postProductoPrecioventa( bodyProductoPrecio:PrecioventaModel, idProducto:number ): Observable<PrecioventaModelResponse>{
        return this.http.post<PrecioventaModelResponse>(ProductoService.productoPath + `/${idProducto}/precio-venta`, bodyProductoPrecio );
    }
    deleteProductoPrecioventa( idProductoPrecioventa:number, idProducto:number ): Observable<PrecioventaModelResponse>{
        return this.http.delete<PrecioventaModelResponse>(ProductoService.productoPath + `/${idProducto}/precio-venta/${idProductoPrecioventa}`);
    }


    // READ ALL PUBLICIDADES
    getProductoUnidadmedida( idProducto:number ): Observable<UnidadesmedidaModel>{
        return this.http.get<UnidadesmedidaModel>(ProductoService.productoPath + `/${idProducto}/unidades-medida` );
    }
    
}
