import { Action } from '@ngrx/store';
import { TipoDocumentoModel,
         BancoModel,
         RolModel,
         SucursalModel,
         TipoComprobanteModel
} from "../models";

export enum StoreActionTypes {
    TipocomprobanteRequested = '[Request Tipocomprobante] Action',
    TipocomprobanteLoaded = '[Load Tipocomprobante] Auth API',
    TipodocumentoRequested = '[Request Tipodocumento] Action',
    TipodocumentoLoaded = '[Load Tipodocumento] Auth API',
    BancoRequested = '[Request Banco] Action',
    BancoLoaded = '[Load Banco] Auth API',
    RolRequested = '[Request Rol] Action',
    RolLoaded = '[Load Rol] Auth API',
    SucursalRequested = '[Request Sucursal] Action',
    SucursalLoaded = '[Load Sucursal] Auth API'
}

export class TipocomprobanteRequested implements Action {
    readonly type = StoreActionTypes.TipocomprobanteRequested;
}

export class TipocomprobanteLoaded implements Action {
    readonly type = StoreActionTypes.TipocomprobanteLoaded;
    constructor(public payload: { tipocomprobante: TipoComprobanteModel[] }) { }
}

export class TipodocumentoRequested implements Action {
    readonly type = StoreActionTypes.TipodocumentoRequested;
}

export class TipodocumentoLoaded implements Action {
    readonly type = StoreActionTypes.TipodocumentoLoaded;
    constructor(public payload: { tipodocumento: TipoDocumentoModel[] }) { }
}

export class BancoRequested implements Action {
    readonly type = StoreActionTypes.BancoRequested;
}

export class BancoLoaded implements Action {
    readonly type = StoreActionTypes.BancoLoaded;
    constructor(public payload: { banco: BancoModel[] }) { }
}

export class RolRequested implements Action {
    readonly type = StoreActionTypes.RolRequested;
}

export class RolLoaded implements Action {
    readonly type = StoreActionTypes.RolLoaded;
    constructor(public payload: { rol: RolModel[] }) { }
}

export class SucursalRequested implements Action {
    readonly type = StoreActionTypes.SucursalRequested;
}

export class SucursalLoaded implements Action {
    readonly type = StoreActionTypes.SucursalLoaded;
    constructor(public payload: { sucursal: SucursalModel[] }) { }
}

export type StoreActions = TipocomprobanteRequested | TipocomprobanteLoaded |
                           TipodocumentoRequested | TipodocumentoLoaded |
                           BancoRequested | BancoLoaded |
                           RolRequested | RolLoaded |
                           SucursalRequested | SucursalLoaded;