// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { GrupoRolModel, GrupoRolListaResponse, GrupoRolModelResponse,GrupoRolUrlResponse,GrupoRolDataModel } from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class GrupoRolService {
    static grupoRolPath = environment.APIEndpoint + '/api-maestras/grupo-rol';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getGrupoRolEnabled( ): Observable<GrupoRolModel[]>{
        return this.http.get<GrupoRolModel[]>(GrupoRolService.grupoRolPath);
    }

    // READ ALL PUBLICIDADES
    getGrupoRolAll( ): Observable<GrupoRolListaResponse>{
        return this.http.get<GrupoRolListaResponse>(GrupoRolService.grupoRolPath  );
    }

    // READ PAQUETE BY ID
    getGrupoRol( idGrupoRol:number ): Observable<GrupoRolModelResponse>{
        return this.http.get<GrupoRolModelResponse>(GrupoRolService.grupoRolPath + `/${idGrupoRol}`);
    }

    // CREATE PUBLICIDAD
    postGrupoRol( bodyGrupoRol:GrupoRolModel ): Observable<GrupoRolModelResponse>{
        return this.http.post<GrupoRolModelResponse>(GrupoRolService.grupoRolPath, bodyGrupoRol );
    }

    // UPDATE PUBLICIDAD BY ID
    putGrupoRol( bodyGrupoRol:GrupoRolModel ): Observable<GrupoRolModelResponse>{
        return this.http.put<GrupoRolModelResponse>(GrupoRolService.grupoRolPath + `/${bodyGrupoRol.grlId}`, bodyGrupoRol);
    }

    // DELETE BY ID
    deleteGrupoRol( idGrupoRol:number ): Observable<GrupoRolModelResponse>{
        return this.http.delete<GrupoRolModelResponse>(GrupoRolService.grupoRolPath + `/${idGrupoRol}`);
    }

    

}
