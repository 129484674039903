// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { CompraModel, CompraListaResponse, CompraModelResponse } from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class CompraService {
    static compraPath = environment.APIEndpoint + 'api-compras/compra';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getCompraEnabled( ): Observable<CompraModel[]>{
        return this.http.get<CompraModel[]>(CompraService.compraPath);
    }

    // READ ALL PUBLICIDADES
    getCompraAll( ): Observable<CompraListaResponse>{
        return this.http.get<CompraListaResponse>(CompraService.compraPath  );
    }
    

    // READ PAQUETE BY ID
    getCompra( idCompra:number ): Observable<CompraModelResponse>{
        return this.http.get<CompraModelResponse>(CompraService.compraPath + `/${idCompra}`);
    }

    // CREATE PUBLICIDAD
    postCompra( bodyCompra:CompraModel ): Observable<CompraModelResponse>{
        return this.http.post<CompraModelResponse>(CompraService.compraPath, bodyCompra );
    }

    // UPDATE PUBLICIDAD BY ID
    putCompra( bodyCompra:CompraModel ): Observable<CompraModelResponse>{
        return this.http.put<CompraModelResponse>(CompraService.compraPath + `/${bodyCompra.cmpId}`, bodyCompra);
    }

    // DELETE BY ID
    deleteCompra( idCompra:number ): Observable<CompraModelResponse>{
        return this.http.delete<CompraModelResponse>(CompraService.compraPath + `/${idCompra}`);
    }


    //Producto anuncio:

    
    
}
