// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { ClienteModelPageResponse, PersonalModelPageResponse, PersonaModel, PersonaModelResponse, ProveedorBancoModel, ProveedorBancoModelResponse, ProveedorModelPageResponse, SimpleResponse } from "../models";

// Environment
import { environment } from 'src/environments/environment';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class PersonaService {
    static path = environment.APIEndpoint + 'api-persona/persona';
    static pathRoles = environment.APIEndpoint + 'api-persona/rol/all';

    constructor(private http: HttpClient) {
    }

    //comprobar
    postValidarDocumento(tipoDocumento:string, nroDocumento:string, tipoConsulta:string=''):Observable<PersonaModelResponse>{
        return this.http.post<PersonaModelResponse>(PersonaService.path + `/validar${tipoConsulta}`, {tipoDocumento:tipoDocumento, nroDocumento:nroDocumento});
    }

    //GET PAGE
    getPageClientes(pageSize: number = 5, currentPage: number = 1, search: string = '', orderBy: string = '1'): Observable<ClienteModelPageResponse> {
        return this.http.get<ClienteModelPageResponse>(PersonaService.path + `/clientes?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}`);
        //+ `?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`
    }

    getPageProveedores(pageSize: number = 5, currentPage: number = 1, search: string = '', orderBy: string = '1'): Observable<ProveedorModelPageResponse> {
        return this.http.get<ProveedorModelPageResponse>(PersonaService.path + `/proveedores?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}`);
        // return this.http.get<ProveedorModelPageResponse>(PersonaService.path + `/proveedores?pageSize=${pageSize}&currentPage=${currentPage}&orderBy=${orderBy}`);
    }
    getPageUsuarios(pageSize: number = 5, currentPage: number = 1, search: string = '', orderBy: string = '1'): Observable<PersonalModelPageResponse> {
        return this.http.get<PersonalModelPageResponse>(PersonaService.path + `/usuarios?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}`);
        //+ `?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`
    }

    //GET
    getUsuario(id: number): Observable<SimpleResponse> {
        return this.http.get<SimpleResponse>(PersonaService.path + '/usuarios' + `/${id}`);
    }
    getProveedor(id: number): Observable<SimpleResponse> {
        return this.http.get<SimpleResponse>(PersonaService.path + '/proveedores' + `/${id}`);
    }
    getCliente(id: number): Observable<SimpleResponse> {
        return this.http.get<SimpleResponse>(PersonaService.path + '/clientes' + `/${id}`);
    }

    //CREATE
    postUsuario(body: PersonaModel): Observable<SimpleResponse> {
        delete body.prsId;
        delete body.prsCliente;
        delete body.prsProveedor;
        delete body.prsUsuario.usrId;
        return this.http.post<SimpleResponse>(PersonaService.path + '/usuario', body);
    }
    postProveedor(body: PersonaModel): Observable<SimpleResponse> {
        delete body.prsId;
        delete body.prsCliente;
        delete body.prsUsuario;
        //delete body.prsProveedor.;
        return this.http.post<SimpleResponse>(PersonaService.path + '/proveedor', body);
    }
    postCliente(body: PersonaModel): Observable<SimpleResponse> {
        delete body.prsProveedor;
        delete body.prsUsuario;
        delete body.prsCliente.cltPersonaId;
        delete body.prsId;
        return this.http.post<SimpleResponse>(PersonaService.path + '/cliente', body);
    }

    // UPDATE
    putUsuario(body: PersonaModel): Observable<PersonaModelResponse> {
        delete body.prsCliente;
        delete body.prsProveedor;
        return this.http.put<PersonaModelResponse>(PersonaService.path + '/usuario' + `/${body.prsId}`, body);
    }
    putProveedor(body: PersonaModel): Observable<PersonaModelResponse> {
        delete body.prsCliente;
        delete body.prsUsuario;
        return this.http.put<PersonaModelResponse>(PersonaService.path + '/proveedor' + `/${body.prsId}`, body);
    }
    putCliente(body: PersonaModel): Observable<SimpleResponse> {
        delete body.prsProveedor;
        delete body.prsUsuario;
        // delete body.prsCliente.cltPersonaId;
        return this.http.put<SimpleResponse>(PersonaService.path + '/cliente' + `/${body.prsId}`, body);
    }

    // DELETE 
    deleteCliente(id: number): Observable<SimpleResponse> {
        return this.http.delete<SimpleResponse>(PersonaService.path + '/cliente' + `/${id}`);
    }
    deleteUsuario(id: number): Observable<SimpleResponse> {
        return this.http.delete<SimpleResponse>(PersonaService.path + '/usuario' + `/${id}`);
    }
    deleteProveedor(id: number): Observable<SimpleResponse> {
        return this.http.delete<SimpleResponse>(PersonaService.path + '/proveedor' + `/${id}`);
    }

    //ROLES
    getRolesAll():Observable<SimpleResponse>{
        return this.http.get<SimpleResponse>(PersonaService.pathRoles);
    }

    //PROVEEDOR BANCO
    getProveedorBancoAll(id:number):Observable<ProveedorBancoModelResponse>{
        return this.http.get<ProveedorBancoModelResponse>(PersonaService.path+'/proveedores/'+id+'/bancos');
    }
    postProveedorBanco(id:number,body: ProveedorBancoModel): Observable<ProveedorBancoModelResponse> {
        delete body.prbId;
        return this.http.post<ProveedorBancoModelResponse>(PersonaService.path+'/proveedores/'+id+'/bancos', body);
    }
    putProveedorBanco(id:number,body: ProveedorBancoModel): Observable<ProveedorBancoModelResponse> {
        return this.http.put<ProveedorBancoModelResponse>(PersonaService.path+'/proveedores/'+id+'/bancos/'+body.prbId, body);
    }
    deleteProveedorBanco(idProveedor:number,idCuenta:number): Observable<SimpleResponse> {
        return this.http.delete<SimpleResponse>(PersonaService.path+'/proveedores/'+idProveedor+'/bancos/'+idCuenta);
    }
}
