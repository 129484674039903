
import { Action, ActionReducerMap, MetaReducer, select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { routerReducer } from '@ngrx/router-store';
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BancoLoaded, BancoRequested, RolLoaded, RolRequested, StoreActionTypes, SucursalLoaded, SucursalRequested, TipocomprobanteLoaded, TipocomprobanteRequested, TipodocumentoLoaded, TipodocumentoRequested } from './store.actions';
import { isBancoLoaded, isRolLoaded, isSucursalLoaded, isTipocomprobanteLoaded, isTipodocumentoLoaded } from './store.selectors';
import { BancoService,
         RolService,
         SucursalService,
         TipoComprobanteService,
         TipoDocumentoService,
} from '../services';
import { BancosModelResponse, 
         RolesModelResponse, 
         SucursalesModelResponse, 
         TiposComprobanteModelResponse, 
         TiposDocumentoModelResponse 
} from '../models';

export interface AppState { }

export const reducers: ActionReducerMap<AppState> = { router: routerReducer };

export const metaReducers: Array<MetaReducer<AppState>> = [];


@Injectable()
export class StoreEffects {

    private returnUrl: string;

    constructor(private actions$: Actions,
                private router: Router,
                private apiTipoDocumentoService: TipoDocumentoService,
                private apiTipoComprobanteService: TipoComprobanteService,
                private apiBancoService: BancoService,
                private apiRolService: RolService,
                private apiSucursalService: SucursalService,
                private store: Store<AppState>
                ) {

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.returnUrl = event.url;
			}
		});
	}

    @Effect({dispatch: false})
    loadTipocomprobante$ = this.actions$
    .pipe(
        ofType<TipocomprobanteRequested>(StoreActionTypes.TipocomprobanteRequested),
        withLatestFrom(this.store.pipe(select(isTipocomprobanteLoaded))),
        filter(([action, _isTipocomprobanteLoaded]) => !_isTipocomprobanteLoaded),
        mergeMap(([action, _isTipocomprobanteLoaded]) => this.apiTipoComprobanteService.getAll()),
        tap((response: TiposComprobanteModelResponse) => {
            console.log('TipoComprobante Effects');
            if (response.transfer_status === '200') {
                console.log(response);
                this.store.dispatch(new TipocomprobanteLoaded({ tipocomprobante : response.data }));
            } else {
                console.log("Tipo Comprobante-Else")
                // this.store.dispatch(new Logout());
            }
        })
    );

    @Effect({dispatch: false})
    loadTipodocumento$ = this.actions$
    .pipe(
        ofType<TipodocumentoRequested>(StoreActionTypes.TipodocumentoRequested),
        withLatestFrom(this.store.pipe(select(isTipodocumentoLoaded))),
        filter(([action, _isTipodocumentoLoaded]) => !_isTipodocumentoLoaded),
        mergeMap(([action, _isTipodocumentoLoaded]) => this.apiTipoDocumentoService.getAll()),
        tap((response: TiposDocumentoModelResponse) => {
            console.log('TipoDocumento Effects');
            if (response.transfer_status === '200') {
                console.log(response);
                this.store.dispatch(new TipodocumentoLoaded({ tipodocumento : response.data }));
            } else {
                console.log("Tipo Documento-Else")
                // this.store.dispatch(new Logout());
            }
        })
    );

    
    @Effect({dispatch: false})
    loadBanco$ = this.actions$
    .pipe(
        ofType<BancoRequested>(StoreActionTypes.BancoRequested),
        withLatestFrom(this.store.pipe(select(isBancoLoaded))),
        filter(([action, _isBancoLoaded]) => !_isBancoLoaded),
        mergeMap(([action, _isBancoLoaded]) => this.apiBancoService.getAll()),
        tap((response: BancosModelResponse) => {
            console.log('Banco Effects');
            if (response.transfer_status === '200') {
                console.log(response);
                this.store.dispatch(new BancoLoaded({ banco : response.data }));
            } else {
                console.log("Banco-Else")
                // this.store.dispatch(new Logout());
            }
        })
    );

    @Effect({dispatch: false})
    loadRol$ = this.actions$
    .pipe(
        ofType<RolRequested>(StoreActionTypes.RolRequested),
        withLatestFrom(this.store.pipe(select(isRolLoaded))),
        filter(([action, _isRolLoaded]) => !_isRolLoaded),
        mergeMap(([action, _isRolLoaded]) => this.apiRolService.getAll()),
        tap((response: RolesModelResponse) => {
            console.log('Rol Effects');
            if (response.transfer_status === '200') {
                console.log(response);
                this.store.dispatch(new RolLoaded({ rol : response.data }));
            } else {
                console.log("Rol-Else")
                // this.store.dispatch(new Logout());
            }
        })
    );

    @Effect({dispatch: false})
    loadSucursal$ = this.actions$
    .pipe(
        ofType<SucursalRequested>(StoreActionTypes.SucursalRequested),
        withLatestFrom(this.store.pipe(select(isSucursalLoaded))),
        filter(([action, _isSucursalLoaded]) => !_isSucursalLoaded),
        mergeMap(([action, _isisSucursalLoaded]) => this.apiSucursalService.getAll()),
        tap((response: SucursalesModelResponse) => {
            console.log('Sucursal Effects');
            if (response.transfer_status === '200') {
                console.log(response);
                this.store.dispatch(new SucursalLoaded({ sucursal : response.data }));
            } else {
                console.log("Banco-Else")
                // this.store.dispatch(new Logout());
            }
        })
    );

}