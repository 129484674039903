// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { MediodepagoModel, MediodepagoListaResponse, MediodepagoModelResponse, MediodepagoUrlResponse } from "../models";
// Environment
import { environment } from 'src/environments/environment';


// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class MediodepagoService {
    static mediodepagoPath = environment.APIEndpoint + '/api-maestras/medio-pago';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getMediodepagoEnabled( ): Observable<MediodepagoModel[]>{
        return this.http.get<MediodepagoModel[]>(MediodepagoService.mediodepagoPath);
    }

    // READ ALL PUBLICIDADES
    getMediodepagoAll( ): Observable<MediodepagoListaResponse>{
        return this.http.get<MediodepagoListaResponse>(MediodepagoService.mediodepagoPath  );
    }

    // READ PAQUETE BY ID
    getMediodepago( idMediodepago:number ): Observable<MediodepagoModelResponse>{
        return this.http.get<MediodepagoModelResponse>(MediodepagoService.mediodepagoPath + `/${idMediodepago}`);
    }

    // CREATE PUBLICIDAD
    postMediodepago( bodyMediodepago:MediodepagoModel ): Observable<MediodepagoModelResponse>{
        return this.http.post<MediodepagoModelResponse>(MediodepagoService.mediodepagoPath, bodyMediodepago );
    }

    // UPDATE PUBLICIDAD BY ID
    putMediodepago( bodyMediodepago:MediodepagoModel ): Observable<MediodepagoModelResponse>{
        return this.http.put<MediodepagoModelResponse>(MediodepagoService.mediodepagoPath + `/${bodyMediodepago.mdpId}`, bodyMediodepago);
    }

    // DELETE BY ID
    deleteMediodepago( idMediodepago:number ): Observable<MediodepagoModelResponse>{
        return this.http.delete<MediodepagoModelResponse>(MediodepagoService.mediodepagoPath + `/${idMediodepago}`);
    }

    

}
