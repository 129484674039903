// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable} from 'rxjs';
// Models
import { SimpleResponse, TransferenciaModelPageResponse } from "../models";

// Environment
import { environment } from 'src/environments/environment';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class TransferenciaService {
    static path = environment.APIEndpoint + 'api-transferencias/transferencias';

    constructor(private http: HttpClient) {}
    
    getTransferenciaPage( pageSize:number = 10, currentPage:number=1, search:string='', orderBy:string='1'): Observable<TransferenciaModelPageResponse>{
        return this.http.get<TransferenciaModelPageResponse>(TransferenciaService.path+`?pageSize=${pageSize}&currentPage=${currentPage}&search=${search.toUpperCase()}`);
    }

    postFinalizarTranferencia( idTransferencia:number ): Observable<SimpleResponse>{
        return this.http.post<SimpleResponse>(TransferenciaService.path + `/finalizar`, {tnfId: idTransferencia} );
    }

}