// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
// RxJS
import { Observable} from 'rxjs';
// Models
import { CargoModel, SimpleResponse } from "../models";

// Environment
import { environment } from 'src/environments/environment';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class CargoService {
    static periodoPath = environment.APIEndpoint + 'api-maestras/cargo';

    constructor(private http: HttpClient) {
    }


    
    getPage( pageSize:number = 5, currentPage:number=1, search:string='', orderBy:string='1' ): Observable<SimpleResponse>{
        return this.http.get<SimpleResponse>(CargoService.periodoPath +`?search=${search.toUpperCase()}`);
    }



    // READ PAQUETE BY ID
    get( id:number ): Observable<SimpleResponse>{
         return this.http.get<SimpleResponse>(CargoService.periodoPath + `/${id}`);
     }
 
     // CREATE PAQUETE
     post( body:CargoModel): Observable<SimpleResponse>{
        return this.http.post<SimpleResponse>(CargoService.periodoPath, body );
      
     }
 
     // UPDATE PAQUETE BY ID
     put( body:CargoModel ): Observable<SimpleResponse>{
         return this.http.put<SimpleResponse>(CargoService.periodoPath + `/${body.crgId}`, body);
        
     }
 
     // DELETE PAQUETE BY ID
     delete( id:number ): Observable<SimpleResponse>{
         return this.http.delete<SimpleResponse>(CargoService.periodoPath + `/${id}`);

       
     }
 
 
}
