<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
    <ol class="breadcrumb pt-0">
        <li class="breadcrumb-item" *ngFor="let sub of pathArr; let i=index" [hidden]="i >= (pathArr.length-1)">
            <a [routerLink]="[getUrl(sub)]">{{'menu.mn'+sub | translate}}</a>
        </li>
        <li *ngIf="!titleStatic" class="breadcrumb-item active" aria-current="page">{{ 'menu.'+preCurrentRoute+'.'+currentRoute | translate }}</li>
        
        <li *ngIf="titleStatic" class="breadcrumb-item">
            <a [routerLink]="[getUrl(currentRoute)]">{{'menu.'+preCurrentRoute+'.'+currentRoute | translate}}</a>
        </li>
        <li *ngIf="titleStatic" class="breadcrumb-item active" aria-current="page">{{ titleStatic }}</li>
    </ol>
</nav>
