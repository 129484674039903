<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;" href="javascript:;"  class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <!-- <div class="search" (click)="searchAreaClick($event)">
      <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div> -->

  </div>


  <a class="navbar-logo" href="/">
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <div class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive" (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>

      <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
        <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
          {{currentLanguage | uppercase}} <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item" href="javascript:;">{{item.label}}</a></li>
        </ul>
      </div>

      <div class="position-relative d-none d-sm-inline-block">
        <div dropdown>
          <button  dropdownToggle type="button" class="header-icon btn btn-empty">
            <i class="simple-icon-grid"></i>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic" id="iconMenuDropdown">
            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-equalizer d-block"></i>
              <span>Settings</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-male-female d-block"></i>
              <span>Users</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-puzzle d-block"></i>
              <span>Components</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-bar-chart-4 d-block"></i>
              <span>Profits</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-file d-block"></i>
              <span>Surveys</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-suitcase d-block"></i>
              <span>Tasks</span>
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="position-relative d-inline-block">

        <div dropdown>
          <button id="button-basic" dropdownToggle type="button" id="notificationButton" class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count">3</span>
          </button>
          <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" id="notificationDropdown">
            <perfect-scrollbar>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/profile-pic-l-2.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/notification-thumb.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">1 item is out of stock!</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/notification-thumb-2.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">New order received! It is total $147,20.</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 ">
                <a href="javascript:;">
                  <img src="/assets/img/notification-thumb-3.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">3 items just added to wish list by a user!
                    </p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div> -->

      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton" (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <!-- <span class="name">{{displayName}}</span> -->
        <span>
          <!-- <img alt="Profile Picture" src="/assets/img/profile-pic-l.jpg" /> -->
          <div class="the-icons"><i class="icon-text iconsminds-male-2"></i></div>
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <!-- <a routerLink="/app/profile/profilepass" class="dropdown-item c-pointer">{{ 'menu.change-password' | translate}}</a> -->
        <a class="dropdown-item c-pointer">{{ displayName }} <br> <strong>{{ displayUser }}</strong><br> <strong>{{ displayEmail }}</strong></a>
        <li class="divider dropdown-divider"></li>
        <a routerLink="/app/applications/admin-profile" class="dropdown-item c-pointer">{{ 'menu.profile' | translate}}</a>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">{{'menu.logout' | translate}}</a>
      </div>
    </div>

  </div>
</nav>
