<div class="menu" (click)="menuClicked($event)">

    <div class="main-menu">
        <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
            <ul class="list-unstyled">
                <li *ngFor="let item of menuItems"
                    [ngClass]="{'active': (selectedParentMenu === item.id && viewingParentMenu === '') || viewingParentMenu === item.id}">

                
                    <ng-container *ngIf="item.label" [ngTemplateOutlet]="menuOwnerTemplate"
			            [ngTemplateOutletContext]="{ item: item }"></ng-container>

                </li>
            </ul>
        </perfect-scrollbar>
    </div>

    <ng-template #menuOwnerTemplate let-item="item" let-parentItem="parentItem">
        <ng-container *ngIf="!item.permission" [ngTemplateOutlet]="menuOwnerInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
        <ng-template *ngIf="item.permission" [ngxPermissionsOnly]="item.permission">
            <ng-container [ngTemplateOutlet]="menuOwnerInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
        </ng-template>
    </ng-template>

    <ng-template #menuOwnerInnerTemplate let-item="item" let-parentItem="parentItem">
        <a *ngIf="item.subs &&  item.subs.length>0"
            (click)="openSubMenu($event,item); false" [href]="item.to">
            <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>
        <a *ngIf="(!item.subs || (item.subs &&  item.subs.length===0))"
            (click)="changeSelectedParentHasNoSubmenu(item.id); false" [routerLink]="item.to">
            <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>
    </ng-template>

    <div class="sub-menu">
        <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
            <ul class="list-unstyled" *ngFor="let item of menuItems" [attr.data-parent]="item.id"
                [ngClass]="{'d-block': (selectedParentMenu === item.id && viewingParentMenu==='') || viewingParentMenu === item.id}">
                <li *ngFor="let sub of item.subs"
                    [ngClass]="{'active': currentUrl === sub.to ,'has-sub-item' : sub.subs&& sub.subs.length>0 }">

                    <ng-container *ngIf="item.label" [ngTemplateOutlet]="menuChildTemplate"
			            [ngTemplateOutletContext]="{ item: sub }"></ng-container>

                    
                    
            </ul>
        </perfect-scrollbar>
    </div>

    <ng-template #menuChildTemplate let-item="item" let-parentItem="parentItem">
        <ng-container *ngIf="!item.permission" [ngTemplateOutlet]="menuChildInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
        <ng-template *ngIf="item.permission" [ngxPermissionsOnly]="item.permission">
            <ng-container [ngTemplateOutlet]="menuChildInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
        </ng-template>
    </ng-template>

    <ng-template #menuChildInnerTemplate let-item="item" let-parentItem="parentItem">
        <a *ngIf="!item.subs || item.subs.length<=0" [routerLink]="[item.to]"
            routerLinkActive="active">
            <i [ngClass]="item.icon"></i>
            <span class="d-inline-block">{{item.label | translate}}</span>
        </a>
        <a (click)="collapseSub.toggle()" *ngIf="item.subs && item.subs.length > 0"
            class="rotate-arrow-icon opacity-50 c-pointer">
            <i class="simple-icon-arrow-down"></i>
            <span class="d-inline-block">{{item.label | translate}}</span>
        </a>
        <div [collapse]="false" #collapseSub="bs-collapse" [isAnimated]="true">
            <div *ngIf="item.subs && item.subs.length > 0">
                <ul class="list-unstyled inner-level-menu">
                    <li *ngFor="let deepSub of item.subs" [ngClass]="{'active': currentUrl === deepSub.to}">
                        <a *ngIf="!deepSub.permission && (!deepSub.subs || deepSub.subs.length<=0)"
                            [routerLink]="[deepSub.to]" routerLinkActive="active">
                            <i [ngClass]="deepSub.icon"></i>
                            <span class="d-inline-block">{{deepSub.label | translate}}</span>
                        </a>
                        <ng-template *ngIf="deepSub.permission" [ngxPermissionsOnly]="deepSub.permission">
                            <a *ngIf="!deepSub.subs || deepSub.subs.length<=0"
                                [routerLink]="[deepSub.to]" routerLinkActive="active">
                                <i [ngClass]="deepSub.icon"></i>
                                <span class="d-inline-block">{{deepSub.label | translate}}</span>
                            </a>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
        
    </ng-template>


</div>