// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { UnidadmedidaModel, UnidadmedidaListaResponse, UnidadmedidaModelResponse, UnidadmedidaUrlResponse } from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class UnidadmedidaService {
    static unidadmedidaPath = environment.APIEndpoint + 'api-maestras/unidadmedida';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getUnidadmedidaEnabled( ): Observable<UnidadmedidaModel[]>{
        return this.http.get<UnidadmedidaModel[]>(UnidadmedidaService.unidadmedidaPath);
    }

    // READ ALL PUBLICIDADES
    getUnidadmedidaAll( ): Observable<UnidadmedidaListaResponse>{
        return this.http.get<UnidadmedidaListaResponse>(UnidadmedidaService.unidadmedidaPath  );
    }

    // READ PAGE PUBLICIDADES
    getUnidadmedidaPage(pageSize: number = 5, currentPage: number = 1, search: string = '', orderBy: string = '1'): Observable<UnidadmedidaListaResponse>{
        return this.http.get<UnidadmedidaListaResponse>(UnidadmedidaService.unidadmedidaPath + `?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}` );
    }

    // READ PAQUETE BY ID
    getUnidadmedida( idUnidadmedida:number ): Observable<UnidadmedidaModelResponse>{
        return this.http.get<UnidadmedidaModelResponse>(UnidadmedidaService.unidadmedidaPath + `/${idUnidadmedida}`);
    }

    // CREATE PUBLICIDAD
    postUnidadmedida( bodyUnidadmedida:UnidadmedidaModel ): Observable<UnidadmedidaModelResponse>{
        return this.http.post<UnidadmedidaModelResponse>(UnidadmedidaService.unidadmedidaPath, bodyUnidadmedida );
    }

    // UPDATE PUBLICIDAD BY ID
    putUnidadmedida( bodyUnidadmedida:UnidadmedidaModel ): Observable<UnidadmedidaModelResponse>{
        return this.http.put<UnidadmedidaModelResponse>(UnidadmedidaService.unidadmedidaPath + `/${bodyUnidadmedida.umdId}`, bodyUnidadmedida);
    }

    // DELETE BY ID
    deleteUnidadmedida( idUnidadmedida:number ): Observable<UnidadmedidaModelResponse>{
        return this.http.delete<UnidadmedidaModelResponse>(UnidadmedidaService.unidadmedidaPath + `/${idUnidadmedida}`);
    }

    

}
