// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
// RxJS
import { Observable} from 'rxjs';
// Models
import { SimpleResponse, TipoDocumentoModel, TipoDocumentoModelResponse, TiposDocumentoModelResponse, ValidarDocumentoModel } from "../models";

// Environment
import { environment } from 'src/environments/environment';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class TipoDocumentoService {
    static path = environment.APIEndpoint + 'api-maestras/tipo-documento';
    static pathValidar = environment.APIEndpoint + 'api-persona/persona/validar';
    constructor(private http: HttpClient) {
    }

    getAll(  ): Observable<TiposDocumentoModelResponse>{
        return this.http.get<TiposDocumentoModelResponse>(TipoDocumentoService.path+'/all' );
        //+ `?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`
    }

    getPage( pageSize:number = 5, currentPage:number=1, search:string='', orderBy:string='1' ): Observable<SimpleResponse>{
        return this.http.get<SimpleResponse>(TipoDocumentoService.path+`?pageSize=${pageSize}&currentPage=${currentPage}` );
        //+ `?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`
    }


    // READ PAQUETE BY ID
    get( id:number ): Observable<SimpleResponse>{
         return this.http.get<SimpleResponse>(TipoDocumentoService.path + `/${id}`);
     }
 
     // CREATE PAQUETE
     post( body:TipoDocumentoModel): Observable<SimpleResponse>{
        delete body.tdcId;
        return this.http.post<SimpleResponse>(TipoDocumentoService.path, body );
      
     }
 
     // UPDATE PAQUETE BY ID
     put( body:TipoDocumentoModel ): Observable<SimpleResponse>{
         return this.http.put<SimpleResponse>(TipoDocumentoService.path + `/${body.tdcId}`, body);
        
     }
 
     // DELETE PAQUETE BY ID
     delete( id:number ): Observable<SimpleResponse>{
         return this.http.delete<SimpleResponse>(TipoDocumentoService.path + `/${id}`);
     }
 

     //comprobar
     comprobar(obj:ValidarDocumentoModel):Observable<SimpleResponse>{
        ///path incompleto
        return this.http.post<SimpleResponse>(TipoDocumentoService.pathValidar,obj);
     }

     
 
}
