// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { RolesModelResponse } from "../models";

// Environment
import { environment } from 'src/environments/environment';

// @Injectable()
@Injectable({
    providedIn: 'root'
})

export class RolService {
    static path = environment.APIEndpoint + 'api-persona/rol';

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<RolesModelResponse> {
        return this.http.get<RolesModelResponse>(RolService.path + `/all`);
    }
}