// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { CategoriaModel, CategoriaListaResponse, CategoriaModelResponse, CategoriaUrlResponse } from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class CategoriaService {
    static categoriaPath = environment.APIEndpoint + '/api-maestras/categoria';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getCategoriaEnabled( ): Observable<CategoriaModel[]>{
        return this.http.get<CategoriaModel[]>(CategoriaService.categoriaPath);
    }

    // READ ALL PUBLICIDADES
    getCategoriaAll( ): Observable<CategoriaListaResponse>{
        return this.http.get<CategoriaListaResponse>(CategoriaService.categoriaPath  );
    }

    // READ PAQUETE BY ID
    getCategoria( idCategoria:number ): Observable<CategoriaModelResponse>{
        return this.http.get<CategoriaModelResponse>(CategoriaService.categoriaPath + `/${idCategoria}`);
    }

    // CREATE PUBLICIDAD
    postCategoria( bodyCategoria:CategoriaModel ): Observable<CategoriaModelResponse>{
        return this.http.post<CategoriaModelResponse>(CategoriaService.categoriaPath, bodyCategoria );
    }

    // UPDATE PUBLICIDAD BY ID
    putCategoria( bodyCategoria:CategoriaModel ): Observable<CategoriaModelResponse>{
        return this.http.put<CategoriaModelResponse>(CategoriaService.categoriaPath + `/${bodyCategoria.ctgId}`, bodyCategoria);
    }

    // DELETE BY ID
    deleteCategoria( idCategoria:number ): Observable<CategoriaModelResponse>{
        return this.http.delete<CategoriaModelResponse>(CategoriaService.categoriaPath + `/${idCategoria}`);
    }
 
   

}
