import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/shared/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private router: Router){}

        intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {

              var nowDate:Date = new Date();
              if(nowDate >= this.authenticationService.getExpiraRefreshToken()){
                this.authenticationService.signOut();
                this.router.navigate(['./']);
              } else {
                const refresh = sessionStorage.getItem(environment.rftoken);
                sessionStorage.removeItem(environment.token);
                let updatedRequest = request;

                this.authenticationService.refreshSignIn(refresh).pipe(first())
                .subscribe(
                  (token) => {
                    updatedRequest = request.clone({
                      headers: request.headers
                                .set('Content-Type' , 'application/json; charset=utf-8')
                                .set('Authorization', 'Bearer ' + sessionStorage.getItem(environment.token))
                    });
                    return next.handle(updatedRequest);
                  },
                );
              }
               
            }
            // else {
            //   this.authenticationService.signOut();
            //   this.router.navigate(['./']);
            // }
            
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}