// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { TalonarioModel, TalonarioListaResponse, TalonarioModelResponse} from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class TalonarioService {
    static talonarioPath = environment.APIEndpoint + 'api-maestras/talonario';
    
    constructor(private http: HttpClient) { }

    

    // READ ALL PUBLICIDADES
    getTalonarioAll( ): Observable<TalonarioListaResponse>{
        return this.http.get<TalonarioListaResponse>(TalonarioService.talonarioPath  );
    }

    // READ PAQUETE BY ID
    getTalonario( idTalonario:number ): Observable<TalonarioModelResponse>{
        return this.http.get<TalonarioModelResponse>(TalonarioService.talonarioPath + `/${idTalonario}`);
    }

    // CREATE PUBLICIDAD
    postTalonario( bodyTalonario:TalonarioModel ): Observable<TalonarioModelResponse>{
        return this.http.post<TalonarioModelResponse>(TalonarioService.talonarioPath, bodyTalonario );
    }

    // UPDATE PUBLICIDAD BY ID
    putTalonario( bodyTalonario:TalonarioModel ): Observable<TalonarioModelResponse>{
        return this.http.put<TalonarioModelResponse>(TalonarioService.talonarioPath + `/${bodyTalonario.tlnId}`, bodyTalonario);
    }

    // DELETE BY ID
    deleteTalonario( idTalonario:number ): Observable<TalonarioModelResponse>{
        return this.http.delete<TalonarioModelResponse>(TalonarioService.talonarioPath + `/${idTalonario}`);
    }

    

}
