import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { LangService } from './shared/lang.service';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { Permiso, PermisoService } from './shared/services';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  constructor(
                private langService: LangService,
                private renderer: Renderer2,
                private apiAuthService: AuthService,
                private apiPermisoService: PermisoService,
                private permissionsService: NgxPermissionsService
              ) {

  }

  ngOnInit() {
    this.langService.init();
    this.loadPermissions();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }

  loadPermissions() {
    var roles:string[] = this.apiAuthService.getRoles();
    var permisos:Permiso[];
    this.permissionsService.flushPermissions();
    if(roles){
      roles.forEach((rol:string)=> {
        permisos = this.apiPermisoService.getPermisosByRol(rol);
        permisos.forEach((pm:Permiso) => {
          // console.log(pm.name);
          this.permissionsService.addPermission(pm.name);
        });  
      });
    }
  }
}
