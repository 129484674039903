// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
// RxJS
import { Observable} from 'rxjs';
// Models
import { CargoModel, PrecioventaDataModelResponse, PrecioventaModelResponse, SimpleResponse, SucursalProductoModelResponse } from "../models";

// Environment
import { environment } from 'src/environments/environment';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class SucursalProductoService {
    static sucursalProductoPath = environment.APIEndpoint + 'api-sucursal-prod/sucursal-producto';

    constructor(private http: HttpClient) {
    }


    
    getListaProductoSucursal( pageSize:number = 5, currentPage:number=1, search:string='', orderBy:string='1' ): Observable<SucursalProductoModelResponse>{
        return this.http.get<SucursalProductoModelResponse>(SucursalProductoService.sucursalProductoPath+`?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`);
    }

    getListaPrecioVenta( pageSize:number = 5, currentPage:number=1, search:string='', orderBy:string='1' ): Observable<PrecioventaDataModelResponse>{
        return this.http.get<PrecioventaDataModelResponse>(SucursalProductoService.sucursalProductoPath+`/precio-venta?pageSize=${pageSize}&currentPage=${currentPage}&search=${search}&orderBy=${orderBy}`);
    }
    
    getPrecioVenta(id:number): Observable<SimpleResponse>{
        return this.http.get<SimpleResponse>(SucursalProductoService.sucursalProductoPath+'/'+id+'/precio-venta');
    }
    
    getPrecioventaById(idPrecioventa:number): Observable<PrecioventaModelResponse>{
        return this.http.get<PrecioventaModelResponse>(SucursalProductoService.sucursalProductoPath+`/precio-venta/${idPrecioventa}`);
    }
 
}
