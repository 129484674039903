// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { CajaModel, CajaListaResponse, CajaModelResponse} from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class CajaService {
    static cajaPath = environment.APIEndpoint + 'api-maestras/caja';
    
    constructor(private http: HttpClient) { }

    

    // READ ALL PUBLICIDADES
    getCajaAll( ): Observable<CajaListaResponse>{
        return this.http.get<CajaListaResponse>(CajaService.cajaPath  );
    }

    // READ PAQUETE BY ID
    getCaja( idCaja:number ): Observable<CajaModelResponse>{
        return this.http.get<CajaModelResponse>(CajaService.cajaPath + `/${idCaja}`);
    }

    // CREATE PUBLICIDAD
    postCaja( bodyCaja:CajaModel ): Observable<CajaModelResponse>{
        return this.http.post<CajaModelResponse>(CajaService.cajaPath, bodyCaja );
    }

    // UPDATE PUBLICIDAD BY ID
    putCaja( bodyCaja:CajaModel ): Observable<CajaModelResponse>{
        return this.http.put<CajaModelResponse>(CajaService.cajaPath + `/${bodyCaja.cajId}`, bodyCaja);
    }

    // DELETE BY ID
    deleteCaja( idCaja:number ): Observable<CajaModelResponse>{
        return this.http.delete<CajaModelResponse>(CajaService.cajaPath + `/${idCaja}`);
    }

    getListaPorSucursal(idSucursal:number): Observable<CajaListaResponse>{
        return this.http.get<CajaListaResponse>(CajaService.cajaPath + `/cajas-sucursal/${idSucursal}`  );
    }

}
