export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  permission?: string;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  // Ventas
  {
    id: 'sales',
    icon: 'simple-icon-basket-loaded',
    label: 'menu.mnsales',
    to: '/app/sales',
    permission: 'accessSalesModule',
    subs: [
      {
        icon: 'simple-icon-note',
        label: 'menu.sales.apppedidodetalle-new',
        to: '/app/sales/apppedidodetalle',
        permission: 'accessSalesApppedidodetalleComponent'
      },      
      {
        icon: 'simple-icon-book-open',
        label: 'menu.sales.apppedido',
        to: '/app/sales/apppedido',
        permission: 'accessSalesApppedidoComponent'
      }
    ]
  },

  // Compras
  {
    id: 'purchases',
    icon: 'iconsminds-box-with-folders',
    label: 'menu.mnpurchases',
    to: '/app/purchases',
    permission: 'accessPurchasesModule',
    subs: [
      {
        icon: 'iconsminds-empty-box',
        label: 'menu.purchases.appcompradetalle-new',
        to: '/app/purchases/appcompradetalle',
        permission: 'accessPurchasesAppcompradetalleComponent'
      },
      {
        icon: 'iconsminds-box-full',
        label: 'menu.purchases.appcompra',
        to: '/app/purchases/appcompra',
        permission: 'accessPurchasesAppcompraComponent'
      }
    ]
  },

  //Caja
  {
    id: 'cashregister',
    icon: 'iconsminds-cash-register-2',
    label: 'menu.mncashregister',
    to: '/app/cashregister',
    permission: 'accessCashregisterModule',
    subs: [
      {
        icon: 'iconsminds-money-bag',
        label: 'menu.cashregister.appcobro',
        to: '/app/cashregister/appcobro',
        permission: 'accessCashregisterAppcobroComponent'
      },
      {
        icon: 'iconsminds-dollar',
        label: 'menu.cashregister.appefectivo',
        to: '/app/cashregister/appefectivo',
        permission: 'accessCashregisterAppefectivoComponent'
      },
      {
        icon: 'simple-icon-notebook',
        label: 'menu.cashregister.appnotacredito',
        to: '/app/cashregister/appnotacredito',
        permission: 'accessCashregisterAppnotacreditoComponent'
      },
      {
        icon: 'simple-icon-notebook',
        label: 'menu.cashregister.appnotadebito',
        to: '/app/cashregister/appnotadebito',
        permission: 'accessCashregisterAppnotadebitoComponent'
      },{
        icon: 'iconsminds-fax',
        label: 'menu.cashregister.appcierrecaja',
        to: '/app/cashregister/appcierrecaja',
        permission: 'accessCashregisterAppcierrecajaComponent'
      }
    ]
  },

  // Almacen
  {
    id: 'store',
    icon: 'iconsminds-green-house',
    label: 'menu.mnstore',
    to: '/app/store',
    permission: 'accessStoreModule',
    subs: [
      {
        icon: 'iconsminds-arrow-mix',
        label: 'menu.store.appinventario',
        to: '/app/store/appinventario',
        permission: 'accessStoreAppinventarioComponent'
      },
      {
        icon: 'iconsminds-arrow-inside-gap',
        label: 'menu.store.appinventarioinicial',
        to: '/app/store/appinventarioinicial',
        permission: 'accessStoreAppinventarioinicialComponent'
      },
      {
        icon: 'iconsminds-dice',
        label: 'menu.store.appkardex',
        to: '/app/store/appkardex',
        permission: 'accessStoreAppInventarioAppkardexComponent'
      },
      {
        icon: 'iconsminds-folder-open',
        label: 'menu.store.apptransferdetalle-new',
        to: '/app/store/apptransferdetalle',
        permission: 'accessStoreApptransferdetalleComponent'
      },{
        icon: 'iconsminds-folder-edit',
        label: 'menu.store.apptransferencia',
        to: '/app/store/apptransferencia',
        permission: 'accessStoreApptransferenciaComponent'
      }
    ]
  },
  // Reportes
  {
    id: 'dashboards',
    icon: 'iconsminds-shop-4',
    label: 'menu.mnreports',
    to: '/app/dashboards',
    subs: [{
      icon: 'simple-icon-briefcase',
      label: 'menu.reports.dashboard',
      to: '/app/dashboards/default'
    }
    ]
  },

  //Gestión
  {
    id: 'management',
    icon: 'iconsminds-management',
    label: 'menu.mnmanagement',
    to: '/app/management',
    permission: 'accessManagementModule',
    subs: [
      {
        icon: 'iconsminds-microscope',
        label: 'menu.management.mnpeople',
        to: '/app/management/people',
        permission: 'accessManagementPeopleModule',
        subs: [
          {
            icon: 'iconsminds-male-female',
            label: 'menu.management.appcliente',
            to: '/app/management/appcliente',
            permission: 'accessManagementAppclienteComponent'
          },
          {
            icon: 'iconsminds-business-mens',
            label: 'menu.management.appproveedor',
            to: '/app/management/appproveedor',
            permission: 'accessManagementAppproveedorComponent'
          },
          {
            icon: 'iconsminds-conference',
            label: 'menu.management.apppersonal',
            to: '/app/management/apppersonal',
            permission: 'accessManagementApppersonalComponent'
          },
          {
            icon: 'iconsminds-tie',
            label: 'menu.management.appcargo',
            to: '/app/management/appcargo',
            permission: 'accessManagementAppcargoComponent'
          }
        ]
      },
      {
        icon: 'iconsminds-microscope',
        label: 'menu.management.mncatalogue',
        to: '/app/management/catalogue',
        permission: 'accessManagementCatalogueModule',
        subs: [
          {
            icon: 'iconsminds-pantone',
            label: 'menu.management.appcategoria',
            to: '/app/management/appcategoria',
            permission: 'accessManagementAppcategoriaComponent'
          },
          {
            icon: 'iconsminds-microscope',
            label: 'menu.management.appmarca',
            to: '/app/management/appmarca',
            permission: 'accessManagementAppmarcaComponent'
          },
          {
            icon: 'iconsminds-sunglasses-w-3',
            label: 'menu.management.apppresentacionproducto',
            to: '/app/management/apppresentacionproducto',
            permission: 'accessManagementApppresentacionproductoComponent'
          },
          {
            icon: 'iconsminds-compass-2',
            label: 'menu.management.appunidadmedida',
            to: '/app/management/appunidadmedida',
            permission: 'accessManagementAppunidadmedidaComponent'
          },
          {
            icon: 'iconsminds-medicine-3',
            label: 'menu.management.appproducto',
            to: '/app/management/appproducto',
            permission: 'accessManagementAppproductoComponent'
          },
          {
            icon: 'iconsminds-clothing-store',
            label: 'menu.management.appsucursalproducto',
            to: '/app/management/appsucursalproducto',
            permission: 'accessManagementAppsucursalproductoComponent'
          },
        ]
      },
      {
        icon: 'iconsminds-library',
        label: 'menu.management.mngeneral',
        to: '/app/management/general',
        permission: 'accessManagementGeneralModule',
        subs: [          
          {
            icon: 'iconsminds-shop',
            label: 'menu.management.appsucursal',
            to: '/app/management/appsucursal',
            permission: 'accessManagementAppsucursalComponent'
          },
          {
            icon: 'iconsminds-project',
            label: 'menu.management.appcaja',
            to: '/app/management/appcaja',
            permission: 'accessManagementAppcajaComponent'
          },
          {
            icon: 'iconsminds-library',
            label: 'menu.management.apptalonario',
            to: '/app/management/apptalonario',
            permission: 'accessManagementApptalonarioComponent'
          }
        ]
      }
    ]
  },

  //Configuración
  {
    id: 'setting',
    icon: 'simple-icon-settings',
    label: 'menu.mnsetting',
    to: '/app/setting',
    permission: 'accessSettingModule',
    subs: [
      {
        icon: 'iconsminds-qr-code',
        label: 'menu.setting.apptipocomprobante',
        to: '/app/setting/apptipocomprobante',
        permission: 'accessSettingApptipocomprobanteComponent'
      },
      {
        icon: 'iconsminds-receipt-4',
        label: 'menu.setting.apptipodocumento',
        to: '/app/setting/apptipodocumento',
        permission: 'accessSettingApptipodocumentoComponent'
      },
      {
        icon: 'iconsminds-credit-card-3',
        label: 'menu.setting.appmediodepago',
        to: '/app/setting/appmediodepago',
        permission: 'accessSettingAppmediodepagoComponent'
      },
      {
        icon: 'iconsminds-bank',
        label: 'menu.setting.appbanco',
        to: '/app/setting/appbanco',
        permission: 'accessSettingAppbancoComponent'
      },      
      {
        icon: 'iconsminds-target-market',
        label: 'menu.setting.appgruporol',
        to: '/app/setting/appgruporol',
        permission: 'accessSettingAppgruporolComponent'
      }
    ]
  }
];
export default data;
