import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  fecha : Date;
  constructor() { 
    this.fecha = new Date();
  }

  ngOnInit() {
  }

}
