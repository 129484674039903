import { Injectable } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';

export interface ISignInCredentials {
  username: string;
  password: string;
  recaptcha: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  static endpoint = environment.APIEndpoint;
  constructor(private http: HttpClient) { }

  signIn(credentials: ISignInCredentials): Observable<any> {

    const credenciales = btoa(environment.clientId + ':' + environment.clientSecret);

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + credenciales
    });

    let params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', credentials.username);
    params.set('password', credentials.password);
    params.set('recaptcha', credentials.recaptcha);

    return this.http.post<any>(AuthService.endpoint + 'auth-api/api/oauth/token', params.toString(), { headers: httpHeaders });
  }

  signOut() {
    if (sessionStorage.getItem(environment.token)) {
      sessionStorage.removeItem(environment.token);
      sessionStorage.removeItem(environment.rftoken);
    }
    // return;
  }

  // register(credentials: ICreateCredentials) {
  //   return from(
  //     this.afAuth.auth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(
  //       () => {
  //         this.afAuth.auth.currentUser.updateProfile({ displayName: credentials.displayName });
  //         this.afAuth.auth.updateCurrentUser(this.afAuth.auth.currentUser);
  //       }
  //     )
  //   );
  // }

  // sendPasswordEmail(email) {
  //   return from(this.afAuth.auth.sendPasswordResetEmail(email));
  // }

  // resetPassword(credentials: IPasswordReset) {
  //   return from(this.afAuth.auth.confirmPasswordReset(credentials.code, credentials.newPassword));
  // }

  // get user(): firebase.User {
  //   return this.afAuth.auth.currentUser;
  // }

  // READ ID
  getIdPersona(): number {
    const decoded = this.getToken();
    const idPersona = decoded != '' ? decoded['sub'] : 0;
    return Number(idPersona);
  }

  public getRoles(): string[] {
    const decoded = this.getToken();
    const roles: string[] = decoded != '' ? decoded['authorities'] : null;
    return roles;
  }

  public getFullName(): string {
    const decoded = this.getToken();
    const fullname = decoded != '' ? decoded['nombre'] : null;
    return fullname;
  }

  public getUserName(): string {
    const decoded = this.getToken();
    const username = decoded != '' ? decoded['user_name'] : null;
    return username;
  }

  public getUserEmail(): string {
    const decoded = this.getToken();
    const username = decoded != '' ? decoded['email'] : null;
    return username;
  }

  public getExpiraToken(): Date {
    const decoded = this.getToken();

    if (decoded['exp'] === undefined) { return null; }

    const date = new Date(0);
    date.setUTCSeconds(decoded['exp']);
    return date;
  }

  getToken(): string {
    const token = sessionStorage.getItem(environment.token);
    return token ? jwt_decode(token) : '';
  }
  public getExpiraRefreshToken(): Date {
    const decoded = this.getRefreshToken();

    if (decoded['exp'] === undefined) { return null; }

    const date = new Date(0);
    date.setUTCSeconds(decoded['exp']);
    return date;
  }

  getRefreshToken(): string {
    const rtoken = sessionStorage.getItem(environment.rftoken);
    return rtoken ? jwt_decode(rtoken) : '';
  }

  refreshSignIn(refresh: string): Observable<any> {

    const credenciales = btoa(environment.clientId + ':' + environment.clientSecret);

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + credenciales
    });

    let params = new URLSearchParams();
    params.set('grant_type', 'refresh_token');
    params.set('refresh_token', refresh);
    return this.http.post<any>(AuthService.endpoint + 'auth-api/api/oauth/token', params.toString(), { headers: httpHeaders })
      .pipe(
        map(response => {
          if (response && response.status_transfer == "200") {
            sessionStorage.setItem(environment.token, response.access_token);
            sessionStorage.setItem(environment.rftoken, response.refresh_token);
          }
          return response.access_token;
        }
        ));
  }
}
