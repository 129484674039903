// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { PresentacionproductoModel, PresentacionproductoListaResponse, PresentacionproductoModelResponse } from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class PresentacionproductoService {
    static presentacionproductoPath = environment.APIEndpoint + '/api-maestras/presentacion-producto';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getPresentacionproductoEnabled( ): Observable<PresentacionproductoModel[]>{
        return this.http.get<PresentacionproductoModel[]>(PresentacionproductoService.presentacionproductoPath);
    }

    // READ ALL PUBLICIDADES
    getPresentacionproductoAll( ): Observable<PresentacionproductoListaResponse>{
        return this.http.get<PresentacionproductoListaResponse>(PresentacionproductoService.presentacionproductoPath  );
    }

    // READ PAQUETE BY ID
    getPresentacionproducto( idPresentacionproducto:number ): Observable<PresentacionproductoModelResponse>{
        return this.http.get<PresentacionproductoModelResponse>(PresentacionproductoService.presentacionproductoPath + `/${idPresentacionproducto}`);
    }

    // CREATE PUBLICIDAD
    postPresentacionproducto( bodyPresentacionproducto:PresentacionproductoModel ): Observable<PresentacionproductoModelResponse>{
        return this.http.post<PresentacionproductoModelResponse>(PresentacionproductoService.presentacionproductoPath, bodyPresentacionproducto );
    }

    // UPDATE PUBLICIDAD BY ID
    putPresentacionproducto( bodyPresentacionproducto:PresentacionproductoModel ): Observable<PresentacionproductoModelResponse>{
        return this.http.put<PresentacionproductoModelResponse>(PresentacionproductoService.presentacionproductoPath + `/${bodyPresentacionproducto.ppdId}`, bodyPresentacionproducto);
    }

    // DELETE BY ID
    deletePresentacionproducto( idPresentacionproducto:number ): Observable<PresentacionproductoModelResponse>{
        return this.http.delete<PresentacionproductoModelResponse>(PresentacionproductoService.presentacionproductoPath + `/${idPresentacionproducto}`);
    }

    

}
