// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable} from 'rxjs';
// Models
import { PedidoModelResponse, PedidoModel, SimpleResponse, PedidoModelPageResponse } from "../models";

// Environment
import { environment } from 'src/environments/environment';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class PedidoService {
    static path = environment.APIEndpoint + 'api-pedido/pedido';

    constructor(private http: HttpClient) {
    }
    
    getPedidoPage( pageSize:number = 10, currentPage:number=1, search:string='', orderBy:string='1', sucursal:string='', estado:string='' ): Observable<PedidoModelPageResponse>{
        return this.http.get<PedidoModelPageResponse>(PedidoService.path+`?pageSize=${pageSize}&currentPage=${currentPage}&search=${search.toUpperCase()}&sucursal=${sucursal}&estado=${estado}`);
    }


    // READ ORDER DETAIL BY ID
    getPedido( idPedido:number ): Observable<PedidoModelResponse>{
         return this.http.get<PedidoModelResponse>(PedidoService.path + `/${idPedido}`);
     }
 
     // CREATE / UPDATE PEDIDO
     postPedido( body:PedidoModel): Observable<PedidoModelResponse>{
        return this.http.post<PedidoModelResponse>(PedidoService.path, body );
     }
 
     // UPDATE PAQUETE BY ID
     put( body:PedidoModel ): Observable<SimpleResponse>{
         return this.http.put<SimpleResponse>(PedidoService.path + `/${body.pddId}`, body);
     }
 
     // DELETE PAQUETE BY ID
     delAnularPedido( id:number ): Observable<SimpleResponse>{
         return this.http.delete<SimpleResponse>(PedidoService.path + `/${id}/anular`);
     }
 
     // DELETE PAQUETE BY ID
     delete( id:number ): Observable<SimpleResponse>{
         return this.http.delete<SimpleResponse>(PedidoService.path + `/${id}`);
     }
 
 
}
