import { Injectable } from '@angular/core';
import { catchError, concatMap, tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/auth.service';
import { DatePipe } from "@angular/common";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) { }
  // function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // how to update the request Parameters
    const token = sessionStorage.getItem(environment.token);
    if(request.url != environment.APIEndpoint+"auth-api/api/oauth/token"){
      request = request.clone({
        headers: request.headers
                .set('Content-Type' , 'application/json; charset=utf-8')
      });
    }
    // console.log(request);
    if (token != null) {
      request = request.clone({
        headers: request.headers
                // .set('Content-Type' , 'application/json; charset=utf-8')
                .set('Authorization', 'Bearer ' + token)
      });
    }

    // logging the updated Parameters to browser's console
    return next.handle(request).pipe(
      tap(
        event => {
          // logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            //console.log('api call success :', event);
          }
        },
        error => {
          // logging the http response to browser's console in case of a failuer
          if (event instanceof HttpResponse) {
            //console.log('api call error :', event);
          }
        }
      )
    );
  }
}
