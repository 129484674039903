// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { SucursalModel, SucursalListaResponse, SucursalModelResponse, SucursalUrlResponse, SucursalesModelResponse } from "../models";
// Environment
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class SucursalService {
    static sucursalPath = environment.APIEndpoint + 'api-maestras/sucursal';
    
    constructor(private http: HttpClient) { }

    // READ ALL PUBLICIDADES FREE
    getSucursalEnabled( ): Observable<SucursalModel[]>{
        return this.http.get<SucursalModel[]>(SucursalService.sucursalPath);
    }

    // READ ALL PUBLICIDADES
    getSucursalAll( ): Observable<SucursalListaResponse>{
        return this.http.get<SucursalListaResponse>(SucursalService.sucursalPath  );
    }

    getAll( ): Observable<SucursalesModelResponse>{
        return this.http.get<SucursalesModelResponse>(SucursalService.sucursalPath + `/all` );
    }

    // READ PAQUETE BY ID
    getSucursal( idSucursal:number ): Observable<SucursalModelResponse>{
        return this.http.get<SucursalModelResponse>(SucursalService.sucursalPath + `/${idSucursal}`);
    }

    // CREATE PUBLICIDAD
    postSucursal( bodySucursal:SucursalModel ): Observable<SucursalModelResponse>{
        return this.http.post<SucursalModelResponse>(SucursalService.sucursalPath, bodySucursal );
    }

    // UPDATE PUBLICIDAD BY ID
    putSucursal( bodySucursal:SucursalModel ): Observable<SucursalModelResponse>{
        return this.http.put<SucursalModelResponse>(SucursalService.sucursalPath + `/${bodySucursal.sclId}`, bodySucursal);
    }

    // DELETE BY ID
    deleteSuscursal( idSucursal:number ): Observable<SucursalModelResponse>{
        return this.http.delete<SucursalModelResponse>(SucursalService.sucursalPath + `/${idSucursal}`);
    }

    putFileS3(url:string, file:File):Observable<HttpEvent<any>>{
        let headers =  new HttpHeaders({ 
              'Content-Type':  file.type
        });
        
        const req = new HttpRequest('PUT', url, file, {
            reportProgress: true,
            responseType: 'json',
            headers: headers
        });
        return this.http.request(req);
    }

}
